import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice";
import products from "./data/products.json";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import axios from "axios";
import TagManager from "react-gtm-module";

// Load environment variables
const API_URL = process.env.REACT_APP_GTM_URL;
const API_USERNAME = process.env.REACT_APP_API_GTM_USERNAME;
const API_PASSWORD = process.env.REACT_APP_API_PASSWORD;

const fetchGtmId = async () => {
  try {
    const response = await axios.get(API_URL, {
      auth: {
        username: API_USERNAME,
        password: API_PASSWORD,
      },
    });
    return response.data.gtm_id;
  } catch (error) {
    console.error("Error fetching GTM ID:", error);
    return null;
  }
};

const initializeGtm = async () => {
  const gtmId = await fetchGtmId();
  if (gtmId) {
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
// store.dispatch(setProducts(products));

const container = document.getElementById("root");
const root = createRoot(container);
initializeGtm().then(() => {
  root.render(
    <Provider store={store}>
      <PersistProvider>
        <App />
      </PersistProvider>
    </Provider>
  );
});
